import { useActiveVariant } from "@intergamma/experiments/hooks"
import { MainLegalFooter } from "@intergamma/legal-footer"
import { withTranslation } from "react-i18next"

const LegalFooter = withTranslation("ig-legal-footer")(MainLegalFooter)

export function Footer() {
  const variant = useActiveVariant("abtest_drl_3215-dba_4")

  return (
    <footer>
      <LegalFooter withSocials={false} withAppPromotion={false} withThuiswinkel={variant === "A-control"} />
    </footer>
  )
}
