import { cn } from "@intergamma/common/cn"
import { Button } from "@intergamma/ui/button"
import { useTranslation } from "react-i18next"
import { useMopinion } from "../hooks/useMopinion"

type MopinionFeedbackProps = {
  className?: string
}

export function MopinionFeedback({ className }: MopinionFeedbackProps) {
  const { t } = useTranslation("checkout-common")
  const mopinion = useMopinion()

  if (!mopinion.loaded) {
    return null
  }

  return (
    <div className={cn("mb-2 flex flex-col items-center gap-2 text-ignew-neutral-1000/60", className)}>
      <span>{t("checkout-common:FeedbackWhatDoYouThink")}</span>
      <Button type="button" variant="secondary" size="small" onClick={mopinion.openModal}>
        {t("checkout-common:Feedback")}
      </Button>
    </div>
  )
}
