import { createHttpClient as T } from "@intergamma/account/login";
import { B as Se, a as Ce, b as xe, c as Ae, d as Oe, e as Ee, U as O, S as j, t as Z, T as ee, f as ke } from "./LoadingHeader-Cin_6rR4.js";
import { C as yt, F as vt, H as Nt, I as wt, g as St, L as Ct, h as xt, i as At, j as Ot } from "./LoadingHeader-Cin_6rR4.js";
import { useTranslation as f } from "react-i18next";
import { useTranslation as kt } from "react-i18next";
import { jsx as n, jsxs as l } from "react/jsx-runtime";
import { Container as Ie } from "@intergamma/container";
import { useState as g, useRef as B, useEffect as N, useMemo as S, useCallback as z, lazy as k, Suspense as R, Children as Le, isValidElement as _e, cloneElement as De } from "react";
import { useLegacyTheme as F, useFormula as Te } from "@intergamma/theme/provider";
import { useQuery as I, keepPreviousData as Be, useQueryClient as ne, useMutation as te } from "@tanstack/react-query";
import { DialogRoot as Me, UnstyledDialogTrigger as Ue, DrawerContent as Re, DialogTitle as Fe, DialogTrigger as D, DialogBottomSheet as re, DialogExtended as oe } from "@intergamma/dialog";
import { DropdownRoot as P, DropdownTrigger as Y, DropdownContent as K } from "@intergamma/dropdown";
import { cn as x } from "@intergamma/common/cn";
import { cn as _ } from "@intergamma/common";
import { MapPin as Pe, LogOut as Ye, ChevronRight as se, ArrowLeft as Ke, X as $e, ChevronDown as qe, ChevronUp as He, Star as ze } from "react-feather";
import { Anchor as C } from "@intergamma/anchor";
import { useConfig as w } from "@intergamma/config";
import { MainSearchBar as Qe, SearchBar as We } from "@intergamma/search-bar";
import { ListItem as E } from "@intergamma/list";
import { dispatchAdobeEvent as M } from "@intergamma/adobe-tracking";
import { signIn as Ge } from "aws-amplify/auth";
import { localStorage as Q } from "@intergamma/storage";
function ie(e) {
  var o;
  const r = T(e.SHOPFRONT_URL, {
    withCredentials: !0
  }), t = ((o = e.locales) == null ? void 0 : o[0].split(/[_-]/)[0]) ?? "nl";
  return {
    getCategories(s) {
      return r.get(e.country === "be" ? `/${t}/resources/menu/${s}` : `/resources/menu/${s}`).then(
        (i) => i.map(
          (a) => ({
            ...a,
            type: "category",
            dataUrl: Ve(a.dataUrl)
          })
        )
      );
    }
  };
}
function Ve(e) {
  return e.replace("https://www.acceptatie.gamma.nl/resources/menu/", "").replace("https://www.gamma.nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/fr/resources/menu/", "").replace("https://www.gamma.be/nl/resources/menu/", "").replace("https://www.gamma.be/fr/resources/menu/", "").replace("https://www.acceptatie.karwei.nl/resources/menu/", "").replace("https://www.karwei.nl/resources/menu/", "");
}
function Xe({ category: e, onSelect: r }) {
  return /* @__PURE__ */ n(
    "li",
    {
      className: x(
        "m-0 block h-[inherit] break-inside-avoid border-b gamma:border-[#a1b5c5] karwei:border-[#dce1e8] lg:flex lg:h-10 lg:items-center lg:border-none",
        e.divider && "mb-10 lg:mb-0"
      ),
      children: /* @__PURE__ */ l(
        "a",
        {
          className: x(
            "group m-0 flex h-auto w-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
            "hover:underline",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            "lg:w-auto lg:grow-0 lg:p-0"
          ),
          href: e.url,
          onClick: (t) => {
            e.hasSubCategories && (r(e), t.preventDefault());
          },
          children: [
            e.icon === "store" && /* @__PURE__ */ n(Pe, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.icon === "logout" && /* @__PURE__ */ n(Ye, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.name,
            e.hasSubCategories && /* @__PURE__ */ n(se, { className: "ml-1 size-4 text-brand-primary transition motion-safe:group-hover:translate-x-[5px] lg:right-[inherit] lg:size-4" })
          ]
        }
      )
    }
  );
}
function Je({ categories: e, onSelect: r }) {
  return /* @__PURE__ */ n("div", { className: "relative", children: /* @__PURE__ */ n("div", { className: "overflow-hidden lg:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "mx-[10px] list-none columns-1 gap-x-5 p-0 pb-5 text-[1em] lg:mx-0 lg:columns-4 lg:px-5 lg:text-[0.9em]", children: e.map((t) => /* @__PURE__ */ n(
    Xe,
    {
      category: t,
      onSelect: r
    },
    t.uid
  )) }) }) });
}
function je({ lastBreadcrumb: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n("div", { className: "rounded-b-lg px-[10px] pb-[15px] lg:px-5 lg:py-[15px] lg:text-75/normal gamma:lg:bg-[#f0f2f6] karwei:lg:bg-[#f1f4f8]", children: /* @__PURE__ */ n(
    C,
    {
      className: "no-underline hover:underline",
      href: e.url,
      children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
    }
  ) });
}
function Ze({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: o } = f("ig-header"), s = e.length;
  return /* @__PURE__ */ l("div", { className: "mt-[10px] flex items-center gap-2 p-[10px] lg:px-[20px]", children: [
    s >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: _(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary lg:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[s - 2]),
        type: "button",
        children: /* @__PURE__ */ n(Ke, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((i, a) => /* @__PURE__ */ l(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex lg:flex",
        children: [
          a === s - 1 ? /* @__PURE__ */ n("span", { className: "font-bold", title: i.name, children: i.name }) : /* @__PURE__ */ n(
            "a",
            {
              className: _(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: i.url,
              onClick: (c) => {
                c.preventDefault(), r(i);
              },
              children: i.name
            }
          ),
          /* @__PURE__ */ n(se, { className: "size-4 group-last:hidden" })
        ]
      },
      i.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        className: _(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": o("close"),
        children: /* @__PURE__ */ n($e, { className: "size-6 stroke-1" })
      }
    )
  ] });
}
function W({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: o,
  onBack: s,
  lastBreadcrumb: i
}) {
  var h;
  const [a, c] = g(!1), u = B(!0), d = B(
    o ? (h = o[0]) == null ? void 0 : h.name : void 0
  );
  return N(() => {
    var b;
    const p = o ? (b = o[0]) == null ? void 0 : b.name : void 0;
    d.current !== p && p && (d.current = p, c(!1), setTimeout(() => {
      u.current && c(!0);
    }, 300));
  }, [o, i.name]), N(
    () => () => {
      u.current = !1;
    },
    []
  ), /* @__PURE__ */ l(
    "nav",
    {
      className: _(
        "overflow-hidden",
        !a && "motion-safe:animate-menu-slider"
      ),
      children: [
        /* @__PURE__ */ n(
          Ze,
          {
            onBack: s,
            breadcrumbs: t,
            onClose: e
          }
        ),
        /* @__PURE__ */ n(Je, { categories: o ?? [], onSelect: r }),
        i.type === "category" && /* @__PURE__ */ n(je, { lastBreadcrumb: i })
      ]
    }
  );
}
function en({
  menuItem: e,
  store: r
}) {
  const t = F(), { t: o } = f("ig-header");
  if (e.type === "category")
    return [];
  if (e.uid === "root-mobile") {
    const s = [
      {
        dataUrl: "categories",
        uid: "catalog",
        type: "category",
        name: o("Categories"),
        url: o("/catalog"),
        hasSubCategories: !0
      },
      {
        uid: "advice",
        type: "menu-item",
        name: o("Advice"),
        url: o("/advice"),
        hasSubCategories: !1
      },
      {
        uid: "services",
        type: "menu-item",
        name: o("ServicesMobile"),
        url: o("/services"),
        hasSubCategories: !1
      },
      {
        uid: "promotions",
        type: "menu-item",
        name: o("Promotions"),
        url: o("/promotions"),
        hasSubCategories: !1
      }
    ];
    return t.name === "karwei_nl" && s.push({
      uid: "inspiration",
      type: "menu-item",
      name: o("Inspiration"),
      url: o("/inspiration"),
      hasSubCategories: !1
    }), s.push({
      uid: "store",
      name: r != null && r.name ? r.name : o("Stores"),
      url: o("/stores"),
      hasSubCategories: !1,
      type: "menu-item",
      icon: "store",
      divider: !0
    }), s;
  }
  return [];
}
function G({
  store: e,
  rootCategory: r
}) {
  const [t, o] = g([r]);
  N(() => {
    o([r]);
  }, [r]);
  const s = t[t.length - 1], i = en({
    menuItem: s,
    store: e
  });
  function a(u) {
    const d = t.findIndex(
      (h) => h.uid === u.uid
    );
    d > -1 && o(t.slice(0, d + 1));
  }
  function c(u) {
    u.hasSubCategories && o([...t, u]);
  }
  return {
    simpleMenuItems: i,
    onBack: a,
    onSelect: c,
    breadcrumbs: t,
    setBreadcrumbs: o,
    lastBreadcrumb: s
  };
}
function nn() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.add("panel-active");
}
function tn() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.remove("panel-active");
}
function rn({
  categories: e,
  store: r,
  onSelect: t
}) {
  const { t: o } = f("ig-header"), [s, i] = g(!1), [a, c] = g(!1), u = B(null), d = S(
    () => new URLSearchParams(
      typeof window < "u" ? window.location.search : void 0
    ),
    []
  ), h = S(
    () => ({
      dataUrl: "categories",
      uid: "root",
      name: o("Catalog"),
      url: o("/catalog"),
      hasSubCategories: !0,
      type: "category"
    }),
    [o]
  ), p = S(
    () => ({
      uid: "root-mobile",
      name: o("Menu"),
      url: "#",
      hasSubCategories: !0,
      type: "menu-item"
    }),
    [o]
  ), {
    breadcrumbs: b,
    lastBreadcrumb: y,
    onBack: v,
    onSelect: A
  } = G({ store: r, rootCategory: h }), {
    breadcrumbs: pe,
    lastBreadcrumb: L,
    onBack: be,
    onSelect: ye,
    simpleMenuItems: ve
  } = G({ store: r, rootCategory: p });
  function Ne(m) {
    m.type === "category" && t(m), ye(m);
  }
  function we(m) {
    m.type === "category" && t(m), A(m);
  }
  N(() => {
    y.type === "category" && t(y);
  }, [y, t]), N(() => {
    L.type === "category" && t(L);
  }, [L, t]);
  function H(m) {
    m ? nn() : tn(), c(m);
  }
  return /* @__PURE__ */ n(Se, { "aria-label": o("MainNavigation"), ref: u, children: /* @__PURE__ */ l(Ce, { children: [
    /* @__PURE__ */ l(xe, { children: [
      /* @__PURE__ */ n("div", { className: "contents lg:hidden", children: /* @__PURE__ */ l(
        Me,
        {
          open: a,
          onOpenChange: (m) => H(m),
          modal: !1,
          children: [
            /* @__PURE__ */ n(Ue, { asChild: !0, children: /* @__PURE__ */ n(Ae, {}) }),
            /* @__PURE__ */ l(Re, { container: u.current, children: [
              /* @__PURE__ */ n("div", { className: "sr-only", children: /* @__PURE__ */ n(Fe, { children: o("Menu") }) }),
              /* @__PURE__ */ n(
                W,
                {
                  onClose: () => H(!1),
                  breadcrumbs: pe,
                  categories: L.type === "category" && e ? e : ve,
                  lastBreadcrumb: L,
                  onBack: be,
                  onSelect: (m) => Ne(m)
                }
              )
            ] }),
            a && /* @__PURE__ */ n("div", { className: "fixed inset-0 z-[9999] bg-[hsl(200deg_60%_12%/75%)] motion-safe:animate-fade-in" })
          ]
        }
      ) }),
      /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ l(P, { open: s, onOpenChange: i, children: [
        /* @__PURE__ */ n(
          Y,
          {
            className: x(
              "h-[46px] rounded-lg",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            children: /* @__PURE__ */ n(Oe, { isOpen: s })
          }
        ),
        /* @__PURE__ */ l(
          K,
          {
            withBackdrop: !0,
            container: u.current,
            align: "start",
            alignOffset: -121,
            sideOffset: -10,
            children: [
              d.has("shadow") && // eslint-disable-next-line tailwindcss/no-custom-classname
              /* @__PURE__ */ n("div", { className: "flex flex-col items-center bg-brand-primary p-4", children: "😎 😎 😎" }),
              /* @__PURE__ */ n(
                W,
                {
                  onClose: () => i(!1),
                  breadcrumbs: b,
                  categories: e,
                  lastBreadcrumb: y,
                  onBack: v,
                  onSelect: (m) => we(m)
                }
              )
            ]
          }
        )
      ] }) })
    ] }),
    /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ n(Ee, {}) })
  ] }) });
}
const $ = "base-navigation", Jn = $;
function on() {
  const [e, r] = g(
    void 0
  ), t = w(), o = S(
    () => ie(t),
    [t]
  ), s = z(
    (u) => o.getCategories(u),
    [o]
  ), i = z(async () => e ? s(e.dataUrl) : [], [e, s]), { data: a } = I({
    queryKey: [$, e == null ? void 0 : e.uid],
    queryFn: i,
    placeholderData: Be,
    throwOnError: !1
  });
  function c(u) {
    r(u);
  }
  return { categories: a, onSelect: c };
}
function jn({ store: e }) {
  const { categories: r, onSelect: t } = on();
  return /* @__PURE__ */ n(rn, { store: e, categories: r, onSelect: t });
}
function Zn({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: x(
        "absolute right-0 top-0 flex h-[50px] items-center gap-[10px] pl-[10px] lg:relative lg:h-[46px]",
        e
      ),
      ...t,
      children: r
    }
  );
}
const sn = k(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), an = k(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
);
var X;
const ln = typeof process !== void 0 && ((X = process.env) == null ? void 0 : X.NEXT_PUBLIC_APP_ENV) === "acc" || typeof window < "u" && window.location.host.includes("acceptatie");
function et({
  user: e,
  container: r,
  onLogin: t,
  ...o
}) {
  const { SHOPFRONT_URL: s } = w(), [i, a] = g(!1), [c, u] = g(!1);
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function d() {
    a(!1);
  }
  N(() => {
    const v = document.getElementById("onetrust-consent-sdk");
    v && (v.style.pointerEvents = "auto");
  }, []), N(() => {
    c && a(!0);
  }, [c]);
  const { isMobile: h } = w(), { t: p } = f("ig-header"), b = h ? re : oe, y = ln ? `${s}/my/` : p("/myaccount");
  return e != null && e.isLoggedIn ? c ? /* @__PURE__ */ n(
    b,
    {
      trigger: /* @__PURE__ */ n(D, { children: /* @__PURE__ */ n(O, { user: e, href: y }) }),
      open: i,
      onOpenChange: a,
      container: r,
      dialogTitle: p("LoyaltyCard"),
      children: /* @__PURE__ */ n(
        an,
        {
          close: () => d(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      )
    }
  ) : /* @__PURE__ */ n(O, { user: e, href: y }) : /* @__PURE__ */ n(
    b,
    {
      trigger: /* @__PURE__ */ n(D, { asChild: !0, children: /* @__PURE__ */ n(O, { user: e }) }),
      open: i,
      onOpenChange: a,
      container: r,
      dialogTitle: p("Login"),
      children: /* @__PURE__ */ n(R, { children: /* @__PURE__ */ n(
        sn,
        {
          ...o,
          setShowLoyaltyBenefits: u,
          onClose: () => d(),
          onLogin: (v) => t(v).then(() => {
            c || d();
          }),
          initLocation: "global_header"
        }
      ) })
    }
  );
}
const cn = k(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), un = k(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
), V = k(
  () => import("@intergamma/theme-next").then((e) => ({
    default: e.IntergammaTheme
  }))
);
var J;
const dn = typeof process !== void 0 && ((J = process.env) == null ? void 0 : J.NEXT_PUBLIC_APP_ENV) === "acc" || typeof window < "u" && window.location.host.includes("acceptatie");
function nt({
  user: e,
  container: r,
  onLogin: t,
  ...o
}) {
  const { SHOPFRONT_URL: s } = w(), [i, a] = g(!1), [c, u] = g(!1), d = Te();
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function h() {
    a(!1);
  }
  N(() => {
    const A = document.getElementById("onetrust-consent-sdk");
    A && (A.style.pointerEvents = "auto");
  }, []), N(() => {
    c && a(!0);
  }, [c]);
  const { isMobile: p } = w(), { t: b } = f("ig-header"), y = p ? re : oe, v = dn ? `${s}/my/` : b("/myaccount");
  return e != null && e.isLoggedIn ? c ? /* @__PURE__ */ n(
    y,
    {
      trigger: /* @__PURE__ */ n(D, { children: /* @__PURE__ */ n(O, { user: e, href: v }) }),
      open: i,
      onOpenChange: a,
      container: r,
      dialogTitle: b("LoyaltyCard"),
      children: /* @__PURE__ */ n(V, { formula: d, children: /* @__PURE__ */ n(
        un,
        {
          close: () => h(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      ) })
    }
  ) : /* @__PURE__ */ n(O, { user: e, href: v }) : /* @__PURE__ */ n(
    y,
    {
      trigger: /* @__PURE__ */ n(D, { asChild: !0, children: /* @__PURE__ */ n(O, { user: e }) }),
      open: i,
      onOpenChange: a,
      container: r,
      dialogTitle: b("Login"),
      children: /* @__PURE__ */ n(R, { children: /* @__PURE__ */ n(V, { formula: d, children: /* @__PURE__ */ n(
        cn,
        {
          ...o,
          setShowLoyaltyBenefits: u,
          onClose: () => h(),
          onLogin: (A) => t(A).then(() => {
            c || h();
          }),
          initLocation: "global_header"
        }
      ) }) })
    }
  );
}
function tt() {
  return /* @__PURE__ */ n(j, { children: /* @__PURE__ */ n(Qe, {}) });
}
function rt(e) {
  return /* @__PURE__ */ n(j, { children: /* @__PURE__ */ n(We, { ...e }) });
}
function ae() {
  return /* @__PURE__ */ n(qe, { className: "pointer-events-none", size: 16 });
}
function le() {
  return /* @__PURE__ */ n(He, { className: "pointer-events-none", size: 16 });
}
function ot({
  alternateURL: e,
  container: r
}) {
  const { t } = f("ig-header"), [o, s] = g(!1);
  return F().name !== "gamma_be" ? null : /* @__PURE__ */ l(P, { open: o, onOpenChange: s, children: [
    /* @__PURE__ */ n(Y, { asChild: !0, children: /* @__PURE__ */ l(
      "button",
      {
        className: _(
          Z,
          "h-auto cursor-pointer border-none bg-none"
        ),
        children: [
          /* @__PURE__ */ n("strong", { children: t("Language") }),
          o ? /* @__PURE__ */ n(le, {}) : /* @__PURE__ */ n(ae, {})
        ]
      }
    ) }),
    /* @__PURE__ */ n(
      K,
      {
        className: "!z-[99999] !w-max",
        align: "end",
        onOpenAutoFocus: mn,
        container: r,
        children: /* @__PURE__ */ n("div", { className: "p-5 text-75/normal", children: /* @__PURE__ */ l("ul", { className: "m-0 list-none p-0", children: [
          /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(
            C,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "nl",
              href: (e == null ? void 0 : e.nl) ?? "/nl",
              children: "Nederlands"
            }
          ) }),
          /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(
            C,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "fr",
              href: (e == null ? void 0 : e.fr) ?? "/fr",
              children: "Français"
            }
          ) })
        ] }) })
      }
    )
  ] });
}
function mn(e) {
  const r = e.target;
  if (!r)
    return;
  const t = r.querySelector("a");
  t && (e.preventDefault(), t.focus());
}
function q(e) {
  const r = T(e, {
    withCredentials: !0
  });
  return {
    getUser() {
      return r.get("/api/session-data");
    },
    getNearbyStores(t) {
      return r.get(
        `/api/session-data/nearby-stores/${t.uid}`
      );
    },
    async changeStore(t) {
      return r.put(
        `/api/session-data/preferred-store/${t.uid}`
      );
    },
    async logout() {
      const t = await this.getGatewayCSRF();
      return r.post("/gateway/logout", null, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    },
    async login(t) {
      const o = await this.getGatewayCSRF();
      return r.post(
        "/gateway/addcard/login",
        t,
        {
          headers: {
            "X-IG-CSRF-TOKEN": o
          }
        }
      );
    },
    async getGatewayCSRF() {
      return (await r.get("/gateway/session")).csrfToken;
    }
  };
}
function st() {
  const { MYACCOUNT_URL: e } = w(), r = ne(), t = S(
    () => q(e),
    [e]
  );
  function o(s) {
    return t.changeStore(s).then(() => {
      document == null || document.body.dispatchEvent(
        new window.CustomEvent("refreshStore", { detail: s })
      ), M({
        type: "change_store",
        data: {
          user_selected_value: s
        }
      });
    });
  }
  return te({
    mutationFn: o,
    throwOnError: !1,
    onSuccess: () => {
      r.invalidateQueries();
    }
  });
}
const ce = "nearby-store";
function it(e) {
  const { MYACCOUNT_URL: r } = w(), t = S(
    () => q(r),
    [r]
  );
  function o() {
    return e ? t.getNearbyStores(e) : [];
  }
  return I({
    queryKey: [ce, e],
    queryFn: o,
    throwOnError: !1
  });
}
function ue({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: x(
        "rounded-b-lg p-5 gamma:bg-[#f0f2f6] karwei:bg-[#f1f4f8]",
        e
      ),
      ...t,
      children: r
    }
  );
}
function de({
  children: e,
  className: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "h3",
    {
      className: x(
        "mb-5 text-[1.2em] font-bold text-brand-primary",
        r
      ),
      ...t,
      children: e
    }
  );
}
function fn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ l(ue, { children: [
    /* @__PURE__ */ n(de, { children: e("OtherStoresNearby") }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(C, { href: e("/stores/search"), children: e("AllStores") }) }),
      /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(C, { href: e("/stores/holidays"), children: e("SpecialOpeningHours") }) })
    ] })
  ] });
}
function hn({ nearbyStore: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "mb-5 grid grid-cols-2 last:mb-0", children: [
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ n(
      "a",
      {
        className: "font-bold text-black no-underline hover:underline",
        href: t("/stores/details", e),
        children: e.name
      }
    ) }),
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ l(
      "button",
      {
        className: "group flex h-auto cursor-pointer items-center border-none bg-transparent p-0 text-[1em] hover:underline",
        onClick: () => r(e),
        children: [
          /* @__PURE__ */ n(ze, { className: "mr-[5px] rounded-[20px] bg-white !p-[5px] group-hover:fill-current gamma:text-brand-primary karwei:text-[#111316]" }),
          t("SaveAsMyStore")
        ]
      }
    ) })
  ] });
}
function gn({ nearbyStores: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l(ue, { children: [
    /* @__PURE__ */ n(de, { children: t("OtherStoresNearby") }),
    e.map((o) => /* @__PURE__ */ n(
      hn,
      {
        nearbyStore: o,
        onSaveStore: r
      },
      o.uid
    )),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(C, { href: t("/stores/search"), children: t("AllStores") }) }),
      /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(C, { href: t("/stores/holidays"), children: t("SpecialOpeningHours") }) })
    ] })
  ] });
}
const pn = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
function bn({ store: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "p-5", children: [
    /* @__PURE__ */ n("h2", { className: "mb-5 text-[1.3em] font-bold text-brand-primary", children: e.name }),
    /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: /* @__PURE__ */ l("div", { className: "leading-normal", children: [
        e.address.street,
        " ",
        e.address.streetNumber,
        e.address.apartment ? `/${e.address.apartment}` : null,
        /* @__PURE__ */ n("br", {}),
        e.address.zipCode,
        " ",
        e.address.city,
        /* @__PURE__ */ n("br", {}),
        e.address.phone,
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n(C, { href: r("/stores/details", { slug: e.slug }), children: r("ShowOnMap") })
      ] }) }),
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: e.openingHours.map((t) => /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: r(pn[t.dayOfWeek]) }),
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: t.opens && t.closes ? r("Timespan", { opens: t.opens, closes: t.closes }) : r("Closed") })
      ] }, t.dayOfWeek)) })
    ] })
  ] });
}
function yn({
  store: e,
  isLoadingNearbyStores: r,
  ...t
}) {
  return /* @__PURE__ */ l("div", { className: "w-[500px] font-primary text-75/normal tabular-nums", children: [
    /* @__PURE__ */ n(bn, { store: e }),
    r ? /* @__PURE__ */ n(fn, {}) : /* @__PURE__ */ n(gn, { ...t })
  ] });
}
function vn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(ee, { href: e("/stores/search"), children: /* @__PURE__ */ n("strong", { children: e("Stores") }) });
}
function at({
  store: e,
  isLoadingNearbyStores: r,
  container: t,
  ...o
}) {
  const [s, i] = g(!1);
  return e ? /* @__PURE__ */ l(P, { open: s, onOpenChange: i, children: [
    /* @__PURE__ */ l(Y, { className: Z, children: [
      /* @__PURE__ */ n("strong", { children: e.name }),
      s ? /* @__PURE__ */ n(le, {}) : /* @__PURE__ */ n(ae, {})
    ] }),
    /* @__PURE__ */ n(
      K,
      {
        className: "z-[999999] !max-w-[500px]",
        align: "end",
        container: t,
        children: /* @__PURE__ */ n(
          yn,
          {
            ...o,
            store: e,
            isLoadingNearbyStores: r
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ n(vn, {});
}
function lt() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(ee, { href: e("/stores/search"), children: /* @__PURE__ */ n(ke, { className: "w-[120px]" }) });
}
function ct({ children: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "nav",
    {
      className: x(
        "absolute right-[220px] top-0 hidden h-[46px] items-center",
        "lg:right-0 lg:flex lg:h-[46px]"
      ),
      "aria-label": r("UserNavigation"),
      children: /* @__PURE__ */ n("ul", { className: "m-0 flex list-none items-center gap-[25px] p-0", children: e && Le.map(e, (t) => _e(t) ? /* @__PURE__ */ n("li", { children: De(t) }) : null) })
    }
  );
}
const Nn = k(
  () => import("./Alert-CxevVYNO.js").then((e) => ({
    default: e.Alert
  }))
);
function wn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(R, { fallback: null, children: e.map(({ sys: t, fields: o }) => /* @__PURE__ */ n(
    Nn,
    {
      label: o.description,
      id: t.id,
      onDismissAlert: r
    },
    t.id
  )) });
}
function Sn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(Ie, { children: /* @__PURE__ */ n(wn, { alerts: e, onDismissAlert: r }) });
}
const U = "disabledAlerts";
function Cn() {
  if (typeof sessionStorage > "u")
    return [];
  const e = sessionStorage.getItem(U);
  if (!e)
    return [];
  try {
    return JSON.parse(e);
  } catch {
    return [];
  }
}
function xn(e) {
  if (typeof sessionStorage > "u")
    return;
  const r = sessionStorage.getItem(U) || "[]";
  try {
    const t = JSON.parse(r);
    t.includes(e) || (t.push(e), sessionStorage.setItem(U, JSON.stringify(t)));
  } catch {
  }
}
const An = T("https://cdn.contentful.com/spaces/");
function On({
  contentfulSpaceId: e,
  contentfulToken: r,
  contentfulLocale: t
}) {
  const o = "alert", s = r;
  return An.get(
    `${e}/environments/master/entries?content_type=${o}&access_token=${s}&locale=${t}`
  ).then((i) => {
    var a;
    return ((a = i == null ? void 0 : i.items) == null ? void 0 : a.length) > 0 ? i.items : [];
  });
}
const me = "alerts";
function En() {
  var u;
  const [e, r] = g(Cn), { contentfulSpaceId: t, contentfulToken: o } = F(), { i18n: s } = f(), i = s.language.substring(0, 2) === "fr" ? "fr" : "nl", a = I({
    queryKey: [me],
    queryFn: () => On({
      contentfulLocale: i,
      contentfulSpaceId: t,
      contentfulToken: o
    }),
    throwOnError: !1
  });
  function c(d) {
    xn(d), r([...e, d]);
  }
  return {
    alerts: ((u = a.data) == null ? void 0 : u.filter(
      (d) => !e.includes(d.sys.id)
    )) ?? [],
    onDismissAlert: c
  };
}
function ut() {
  const { alerts: e, onDismissAlert: r } = En();
  return /* @__PURE__ */ n(Sn, { alerts: e, onDismissAlert: r });
}
function kn(e) {
  const r = T(e, {
    withCredentials: !0
  });
  return {
    async getNumberOfProducts() {
      const t = await r.get(
        "/resources/cart/numberOfProducts"
      );
      return Number.isNaN(t) ? 0 : t;
    }
  };
}
const fe = "number-of-products";
function dt() {
  const { CHECKOUT_URL: e } = w(), r = S(
    () => kn(e),
    [e]
  );
  function t() {
    return r.getNumberOfProducts();
  }
  return I({
    queryKey: [fe],
    queryFn: t,
    throwOnError: !1
  });
}
const In = "wishlist";
function Ln() {
  var r;
  if (typeof Q > "u")
    return 0;
  const e = Q.getItem(In);
  return e ? (r = JSON.parse(e)) == null ? void 0 : r.length : 0;
}
const he = "number-of-favorites";
function mt() {
  return I({
    queryKey: [he],
    queryFn: Ln,
    throwOnError: !1,
    initialData: 0
  });
}
const ge = "user";
function ft() {
  const { MYACCOUNT_URL: e } = w(), r = S(
    () => q(e),
    [e]
  );
  return I({
    queryKey: [ge],
    queryFn: r.getUser,
    throwOnError: !1
  });
}
function ht() {
  const { t: e } = f("ig-header"), r = ne();
  function t({ body: o }) {
    return Ge({
      username: o.customerId,
      password: o.password,
      options: {
        authFlowType: "USER_PASSWORD_AUTH"
      }
    }).catch((s) => {
      const { name: i, message: a } = s;
      throw i === "NotAuthorizedException" ? a === "Password attempts exceeded" ? new Error(e("loginTooManyAttempts")) : new Error(e("loginInvalidPassword")) : i === "UserNotFoundException" ? new Error(e("loginUserNotFound")) : new Error(e("loginSomethingWentWrong"));
    });
  }
  return te({
    mutationFn: t,
    throwOnError: !1,
    onSuccess: (o, { disableAdobeEvent: s = !1, body: i }) => {
      s || M({
        type: "login_header",
        data: {
          kdbid: i.customerId
        }
      }), r.resetQueries({
        queryKey: [
          me,
          $,
          ce,
          he,
          fe,
          ge
        ]
      });
    },
    onError: (o) => {
      M({
        type: "login_error_header",
        data: {
          user_message_type: (o == null ? void 0 : o.message) ?? ""
        }
      });
    }
  });
}
function gt(e) {
  return ie(e).getCategories;
}
export {
  me as ALERTS_QUERY_KEY,
  $ as BASE_NAVIGATION_QUERY_KEY,
  rn as BaseNavigation,
  yt as CartIconNavigation,
  vt as FavoritesIconNavigation,
  Sn as HeaderAlerts,
  Nt as HeaderContainer,
  wt as IconsNavigation,
  Zn as IconsNavigationContainer,
  ot as LanguageSelector,
  St as LoadingBaseNavigation,
  Ct as LoadingHeader,
  xt as LoadingIconsNavigation,
  At as LoadingSearch,
  lt as LoadingStores,
  Ot as LoadingTopNavigation,
  jn as MainBaseNavigation,
  ut as MainHeaderAlerts,
  tt as MainSearch,
  ce as NEARBY_STORES_QUERY_KEY,
  he as NUMBER_OF_FAVORITES_QUERY_KEY,
  Jn as QUERY_KEY,
  rt as Search,
  j as SearchContainer,
  at as StoreLocatorDropdown,
  nt as StyledUserIconNavigation,
  ct as TopNavigation,
  ge as USER_QUERY_KEY,
  et as UserIconNavigation,
  O as UserIconNavigationButton,
  gt as createGetAssortimentCategories,
  nn as disablePageScroll,
  tn as enablePageScroll,
  On as getAlerts,
  Cn as getSessionDisabledAlert,
  xn as setSessionDisabledAlert,
  En as useAlertsQuery,
  on as useCategoriesQuery,
  st as useChangeStoreMutation,
  ht as useLoginMutation,
  it as useNearbyStoresQuery,
  mt as useNumberOfFavorites,
  dt as useNumberOfProductsQuery,
  kt as useTranslation,
  ft as useUserQuery
};
