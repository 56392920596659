import { A as l, a as P, b as d, F as g, c as p, L as y, d as h, R as A, e as C, m as k, S as m, n as S, V as F, f as L, g as V, i as R, u as W, h as U, j as v, k as T, l as $, v as w } from "./LoyaltyBenefits-kHvA_fw2.js";
import { a as H, i as I, l as j, s as B } from "./datadog-logging-CPRR9qp_.js";
import { c as G, g as M, u as _ } from "./useAuthenticate--pVaRqKd.js";
import { I as Q, c as b, a as z, g as J, w as K } from "./index-C2EW4nef.js";
import { datadogLogs as X } from "@datadog/browser-logs";
const i = [
  { country: "NL", prefix: "+31", altPrefix: "0031", trunkPrefix: "0" },
  // The Netherlands
  { country: "BE", prefix: "+32", altPrefix: "0032", trunkPrefix: "0" },
  // Belgium
  { country: "DE", prefix: "+49", altPrefix: "0049", trunkPrefix: "0" },
  // Germany
  { country: "FR", prefix: "+33", altPrefix: "0033", trunkPrefix: "0" },
  // France
  { country: "LU", prefix: "+352", altPrefix: "00352", trunkPrefix: null }
  // Luxembourg
], u = (r, t) => {
  var o;
  if (i.some(
    ({ prefix: e }) => t.startsWith(e)
  ))
    return t;
  const a = i.find(
    ({ altPrefix: e }) => t.startsWith(e)
  );
  if (a)
    return t.replace(
      a.altPrefix,
      a.prefix
    );
  const s = ((o = i.find(
    ({ prefix: e, altPrefix: n }) => e === r || n === r
  )) == null ? void 0 : o.trunkPrefix) || null;
  return s && t.startsWith(s) ? `${r}${t.slice(s.length)}` : `${r}${t}`;
};
export {
  l as Account,
  P as AccountContext,
  d as AccountProvider,
  g as Flow,
  Q as IG_APP_AUTH,
  p as LoginForm,
  y as LoyaltyBenefits,
  h as LoyaltyCardForm,
  A as ResendSuccess,
  C as ResendVerification,
  k as Section,
  m as SignUpForm,
  S as VerificationMethod,
  F as VerifyForm,
  L as VerifySuccess,
  H as addRequestModeToLogContext,
  G as configureAuth,
  b as createHttpClient,
  X as datadog,
  M as getAuthConfiguration,
  z as getHeaders,
  J as getIdToken,
  V as getUrls,
  I as initDataDog,
  R as initialState,
  u as joinPhoneNumber,
  j as log,
  B as setGlobalContextProperty,
  W as useAccount,
  U as useAccountQuery,
  _ as useAuthenticate,
  v as useResendVerification,
  T as useSignUp,
  $ as useVerifyCode,
  w as validateCode,
  i as verificationPhoneFormats,
  K as withCancelToken
};
