import { useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { useCartQuery } from "api/cart"
import type { ViewCartDispatchEventData } from "api/generated/checkout"
import type { AxiosRequestConfig } from "axios"
import { useEffect } from "react"

export const QUERY_KEY = "viewcart"

export function useTrackingViewCart() {
  const queryClient = useQueryClient()
  const cartQuery = useCartQuery()

  useEffect(() => {
    if (cartQuery.data) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
    }
  }, [cartQuery.data, queryClient])

  return useQuery({
    enabled: !!cartQuery.data, // wait for cart data to be available first
    queryKey: [QUERY_KEY],
    queryFn: ({ signal }) => fetchTrackingViewCart({ signal }),
  })
}

async function fetchTrackingViewCart(config: AxiosRequestConfig) {
  const response = await api.get<ViewCartDispatchEventData>("/api/data/viewcart", config)
  return response.data
}
