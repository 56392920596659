import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import type { CurrentCustomer } from "types"

const QUERY_KEY_CURRENT_CUSTOMER = "current-customer"

export function useCurrentCustomerQuery() {
  return useQuery({
    queryKey: [QUERY_KEY_CURRENT_CUSTOMER],
    queryFn: ({ signal }) => {
      return api.get<CurrentCustomer>("/api/customer/current", { signal }).then((res) => res.data)
    },
  })
}
