import { joinPhoneNumber } from "@intergamma/account"
import { phoneFormats } from "@intergamma/validators"
import type { AddressDetails } from "api/generated/checkout"
import { produce, setAutoFreeze } from "immer"
import type { IFullCheckoutFormValuesWithMeta } from "../types"

// This shouldn't be necessary, but will throw an error if enabled.
setAutoFreeze(false)

/**
 * Removes municipality property and adds postalCode and city properties.
 */
export function transformFormValues({ meta, ...values }: Partial<IFullCheckoutFormValuesWithMeta>) {
  return {
    ...values,
    billingAddress: produce(values.billingAddress, (draft) => {
      if (draft?.municipality) {
        const [postalCode, city] = draft.municipality.split(/\s(.*)/)
        draft.postalCode = postalCode
        draft.city = city
        delete draft.municipality
      }

      if (draft?.phoneNumber) {
        const phoneCountry = values.billingAddress!.phoneCountry as keyof typeof phoneFormats
        const phoneFormat = phoneFormats[phoneCountry]
        const cleanedPhoneNumber = values
          .billingAddress!.phoneNumber!.replace(/\s/g, "")
          .replace(/-/g, "")
          .replace(/\//g, "")
        const phonePrefix = `+${phoneFormat.prefix}`
        const phone = cleanedPhoneNumber ? joinPhoneNumber(phonePrefix, cleanedPhoneNumber) : undefined
        draft.phoneNumber = phone
        delete draft.phoneCountry
      }

      return draft
    }) as AddressDetails,
    shippingAddress: produce(values.shippingAddress, (draft) => {
      if (draft?.municipality) {
        const [postalCode, city] = draft.municipality.split(/\s(.*)/)
        draft.postalCode = postalCode
        draft.city = city
        delete draft.municipality
      }

      return draft
    }) as AddressDetails,
  }
}
