import { datadogLogs } from "@datadog/browser-logs"
import { fetchAuthSession } from "aws-amplify/auth"
import axios from "axios"
import { formulaConfig } from "config/formula"
import { decodeJwt } from "jose"
import Cookies from "js-cookie"

export const api = axios.create({ baseURL: formulaConfig.CHECKOUT_URL, withCredentials: true })
export const myAccountApi = axios.create({ baseURL: formulaConfig.MYACCOUNT_URL, withCredentials: true })

api.interceptors.request.use(async (config) => {
  const token = await getIdToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  const cookies = Cookies.get()
  const cognitoCookieName = Object.keys(cookies).find(
    (key) => key.startsWith("CognitoIdentityServiceProvider") && key.endsWith("idToken")
  )
  if (!token && cognitoCookieName && cookies[cognitoCookieName]) {
    const decoded = decodeJwt(cookies[cognitoCookieName])
    const exp = decoded?.exp ?? "unknown"
    const expired = exp !== "unknown" ? Date.now() / 1000 - exp > 0 : "unknown"

    datadogLogs.logger.info("No Authorization header, but has cognito cookie", {
      cognitoCookie: cookies[cognitoCookieName],
      exp,
      expired,
    })
  }

  return config
})

myAccountApi.interceptors.request.use(async (config) => {
  const token = await getIdToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

async function getIdToken() {
  const appIdToken = Cookies.get("IGAppAuth")

  if (appIdToken) {
    return appIdToken
  }

  try {
    const { tokens } = await fetchAuthSession()

    return tokens?.idToken?.toString() ?? null
  } catch (error) {
    datadogLogs.logger.error("Could not fetch auth session", {}, error as Error)

    return null
  }
}
