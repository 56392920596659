import { phoneFormats } from "@intergamma/validators"
import { useQuery } from "@tanstack/react-query"
import { useCheckoutPageQuery } from "api/checkout-page"
import { formulaConfig } from "config/formula"
import { globalConfig } from "config/global"
import { useMultipartPaymentData } from "features/multipart-payment/hooks/useMultipartPaymentData"
import { getDateString } from "lib/calendar"
import { splitPhoneNumber } from "../helpers/phoneNumber"
import type { PaymentMethodBrand } from "../types"
import { getPersistedCheckoutForm } from "./useCheckoutFormPersist"
import { useCustomerDetails } from "./useCustomerDetails"

export function useDefaultCheckoutValuesQuery(paymentMethod?: PaymentMethodBrand) {
  const multipartPaymentData = useMultipartPaymentData()
  const authenticatedCustomerData = useCustomerDetails()
  const checkoutPageQuery = useCheckoutPageQuery()

  return useQuery({
    queryKey: [
      "checkout-default-values",
      multipartPaymentData,
      authenticatedCustomerData,
      checkoutPageQuery.data?.customerDetails,
    ],
    enabled: multipartPaymentData && authenticatedCustomerData && !!checkoutPageQuery.data,
    refetchOnWindowFocus: false,
    queryFn: () => {
      const persisted = getPersistedCheckoutForm()
      const { billingAddress, hasShippingAddress, shippingAddress } = checkoutPageQuery.data?.customerDetails ?? {}
      const payment = multipartPaymentData
        ? { multiPartReference: multipartPaymentData.multiPartReference ?? "" }
        : {
            ...(persisted.payment ?? {}),
            ...(paymentMethod && { type: paymentMethod }),
            ...(persisted.payment?.type === "giftcard" &&
              persisted.payment?.brand &&
              !globalConfig.isProduction && { additionalData: { testBalanceOverride: "1" } }),
          }

      const country = billingAddress?.country || persisted.billingAddress?.country || formulaConfig.country
      const billingPostalCode = billingAddress?.postalCode || persisted.billingAddress?.postalCode || ""
      const billingCity = billingAddress?.city || persisted.billingAddress?.city || ""
      const shippingPostalCode = shippingAddress?.postalCode || persisted.shippingAddress?.postalCode || ""
      const shippingCity = shippingAddress?.city || persisted.shippingAddress?.city || ""
      const phoneNumberInfo = splitPhoneNumber(
        billingAddress?.phoneNumber || persisted.billingAddress?.phoneNumber || ""
      )

      // Special case
      if (paymentMethod === "bcmc") {
        return {
          payment: {
            ...payment,
            type: "bcmc",
          },
        }
      }

      return {
        meta: {
          isLoggedIn: !!authenticatedCustomerData,
        },
        billingAddress: {
          firstName: billingAddress?.firstName || persisted.billingAddress?.firstName || "",
          lastName: billingAddress?.lastName || persisted.billingAddress?.lastName || "",
          street: billingAddress?.street || persisted.billingAddress?.street || "",
          streetNumber: billingAddress?.streetNumber || persisted.billingAddress?.streetNumber || "",
          apartment: billingAddress?.apartment || persisted.billingAddress?.apartment || "",
          phoneCountry:
            phoneNumberInfo.country ||
            Object.values(phoneFormats).find(({ cc }) => cc === country)?.cc ||
            formulaConfig.country,
          phoneNumber: phoneNumberInfo.phoneNumber || "",
          businessCustomer: billingAddress?.businessCustomer || persisted.billingAddress?.businessCustomer || false,
          companyName: billingAddress?.companyName || persisted.billingAddress?.companyName || "",
          vatNumberRequired: billingAddress?.vatNumberRequired || persisted.billingAddress?.vatNumberRequired || false,
          vatNumber: billingAddress?.vatNumber || persisted.billingAddress?.vatNumber || "",
          country,
          email: billingAddress?.email || persisted.billingAddress?.email || "",
          ...(country === "BE"
            ? {
                municipality: billingPostalCode && billingCity ? `${billingPostalCode} ${billingCity}` : "",
              }
            : {
                postalCode: billingPostalCode || persisted.billingPostalCode || "",
                city: billingCity || persisted.billingCity || "",
              }),
        },
        updateAccount: true,
        hasShippingAddress: country !== formulaConfig.country || hasShippingAddress || false,
        shippingAddress: {
          firstName: shippingAddress?.firstName || persisted.shippingAddress?.firstName || "",
          lastName: shippingAddress?.lastName || persisted.shippingAddress?.lastName || "",
          street: shippingAddress?.street || persisted.shippingAddress?.street || "",
          streetNumber: shippingAddress?.streetNumber || persisted.shippingAddress?.streetNumber || "",
          apartment: shippingAddress?.apartment || persisted.shippingAddress?.apartment || "",
          country: formulaConfig.country,
          ...(formulaConfig.country === "BE" && {
            municipality: shippingPostalCode && shippingCity ? `${shippingPostalCode} ${shippingCity}` : "",
          }),
          ...(formulaConfig.country === "NL" && {
            postalCode: shippingPostalCode,
            city: shippingCity,
          }),
        },
        delivery: {
          type: checkoutPageQuery.data?.cartSummary.delivery.type,
          shipments: checkoutPageQuery.data?.shipments.map((shipment) => ({
            id: shipment.id,
            selectedShift: getDateString(shipment.defaultShiftDate),
            pickupStoreUid: checkoutPageQuery.data?.cartSummary.pickupStore?.uid,
            pickupStoreName: checkoutPageQuery.data?.cartSummary.pickupStore?.name,
          })),
        },
        hasAgreedToServiceCondition: false,
        payment,
      }
    },
  })
}
