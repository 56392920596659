import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import type {
  AdaptedShipment,
  CartSummaryData,
  CartSummaryDataResponse,
  CheckoutFormValues,
  ShipmentResponse,
} from "features/checkout/types"
import { adaptShipmentsData } from "helpers/adaptShipmentsData"
import { mapCartSummary } from "helpers/mappers/cartSummary"
import type { CartEntryValidationError, CustomerDetails } from "./generated/checkout"

export interface CheckoutPageResponse {
  resultCode: "OK" | "UNSUPPORTED"
  cartSummary: CartSummaryDataResponse
  customerDetails: CustomerDetails
  shipments: ShipmentResponse
  validationErrors?: Array<CartEntryValidationError>
}

export interface CheckoutPageData {
  cartSummary: CartSummaryData
  customerDetails: CustomerDetails
  shipments: AdaptedShipment[]
  validationErrors: Array<CartEntryValidationError>
}

export const QUERY_KEY_CHECKOUT_PAGE = "checkout-page"

export function useCheckoutPageQuery({ enabled = true } = {}) {
  return useQuery({
    enabled,
    queryKey: [QUERY_KEY_CHECKOUT_PAGE],
    queryFn: async ({ signal }) => {
      const { data } = await api.get<CheckoutPageResponse>("/api/checkout/data", { signal })
      const mappedCartSummary = mapCartSummary(data.cartSummary)

      return {
        cartSummary: mappedCartSummary,
        customerDetails: data.customerDetails,
        shipments: adaptShipmentsData(data.shipments, mappedCartSummary),
        validationErrors: data.validationErrors,
      } as CheckoutPageData
    },
  })
}

export const MUTATION_KEY_UPDATE_CHECKOUT_PAGE = "update-checkout-page"

export function useUpdateCheckoutPageMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [MUTATION_KEY_UPDATE_CHECKOUT_PAGE],
    mutationFn: async (payload: Partial<CheckoutFormValues>) => {
      const { data } = await api.put<CheckoutPageResponse>("/api/checkout/data", payload)
      const mappedCartSummary = mapCartSummary(data.cartSummary)

      return {
        cartSummary: mappedCartSummary,
        customerDetails: data.customerDetails,
        shipments: adaptShipmentsData(data.shipments, mappedCartSummary),
        validationErrors: data.validationErrors,
      } as CheckoutPageData
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CHECKOUT_PAGE], data)
    },
  })
}
