import {
  CartIconNavigation,
  HeaderContainer,
  IconsNavigation,
  UserIconNavigation,
  useLoginMutation,
} from "@intergamma/header"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useCurrentCustomerQuery } from "api/current-customer"
import { useSessionDataQuery } from "api/myaccount/session-data"
import { useNumberOfProductsQuery } from "api/number-of-products"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export function SimplifiedHeader() {
  const currentCustomerQuery = useCurrentCustomerQuery()
  const checkoutPageQuery = useCheckoutPageQuery()
  const loginMutation = useLoginMutation()
  const sessionDataQuery = useSessionDataQuery()
  const numberOfProductsQuery = useNumberOfProductsQuery()
  const { t } = useTranslation("ig-header")

  const isLoginStatusShown = sessionDataQuery.isSuccess && !sessionDataQuery.data?.isLoggedIn

  return (
    <header>
      <HeaderContainer variant="simple">
        <IconsNavigation variant="simple">
          {isLoginStatusShown && (
            <UserIconNavigation
              user={sessionDataQuery.data}
              isLoading={loginMutation.isPending}
              onLogin={async (body) => {
                await loginMutation.mutateAsync(body)
                await Promise.all([
                  checkoutPageQuery.refetch(),
                  currentCustomerQuery.refetch(),
                  sessionDataQuery.refetch(),
                ])
                toast.success(t("loginSuccess"))
              }}
              errorMessage={loginMutation.error?.message ?? undefined}
            />
          )}

          <CartIconNavigation numberOfProducts={numberOfProductsQuery.data ?? 0} />
        </IconsNavigation>
      </HeaderContainer>
    </header>
  )
}
