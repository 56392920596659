import { Button } from "@intergamma/ui/button"
import { AutocompleteField } from "@intergamma/ui/form"
import { useStoreQuery } from "api/store"
import { Container } from "components/Container"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { formulaConfig } from "config/formula"
import { useController, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { useSpecialist } from "./hooks/useSpecialist"

export function SpecialistPage() {
  const { t } = useTranslation(["checkout-common"])
  const specialist = useSpecialist()
  const form = useForm({
    defaultValues: {
      storeUid: specialist.store?.uid ?? "",
      storeName: specialist.store?.name ?? "",
    },
    mode: "onTouched",
  })
  const autocomplete = useController({
    name: "storeName",
    control: form.control,
    defaultValue: "",
    rules: {
      validate(value: unknown) {
        const result = z.string().min(1, t("checkout-common:ChooseAStore")).safeParse(value)

        return result.success ? true : result.error.issues[0]?.message
      },
    },
  })

  const storeName = form.watch("storeName")
  const storeQuery = useStoreQuery(storeName, { pickup: false })

  const onSubmit = form.handleSubmit(({ storeUid, storeName }) => {
    specialist.setStore({ uid: storeUid, name: storeName })
    window.location.href = formulaConfig.SHOPFRONT_URL
  })

  return (
    <Page moduleName="specialist">
      <Main>
        <Container>
          <header className="py-4 lg:py-8">
            <h1 className="text-500/6 font-bold text-brand-primary antialiased">{t("checkout-common:Specialist")}</h1>
          </header>
          <div className="flex max-w-xl flex-col gap-4">
            <p>{t("checkout-common:SpecialistLabel")}</p>
            <form onSubmit={onSubmit}>
              <AutocompleteField
                {...autocomplete.field}
                label="Bouwmarkt"
                error={autocomplete.fieldState.error?.message}
                renderItem={(item) => <span>{item.name}</span>}
                data-dd-privacy="allow"
                options={{
                  items: storeQuery.data ?? [],
                  inputValue: autocomplete.field.value,
                  itemToKey: (item) => item?.name ?? "",
                  itemToString: (item) => item?.name ?? "",
                  onInputValueChange: ({ inputValue }) => autocomplete.field.onChange(inputValue),
                  onSelectedItemChange: ({ selectedItem }) => {
                    form.setValue("storeUid", selectedItem.uid ?? "")
                    autocomplete.field.onChange(selectedItem.name)
                  },
                }}
              />
              <input {...form.register("storeUid")} type="hidden" />
              <Button type="submit" variant="primary" className="mt-4">
                {t("checkout-common:SpecialistCTA")}
              </Button>
            </form>
          </div>
        </Container>
      </Main>
    </Page>
  )
}
