import { beMunicipalityRegex, emojiRegex } from "@intergamma/regex"
import { isValidPhoneNumber } from "@intergamma/validators"
import { formulaConfig } from "config/formula"
import { countrySelectOptions } from "features/checkout/helpers/getCountryNameByCountryCode"
import type { IFullCheckoutFormValues } from "features/checkout/types"
import { email } from "features/validation/common"
import type { TFunction } from "i18next"
import { z } from "zod"
import {
  forbiddenCharactersRegex,
  forbiddenCharactersStreetCityRegex,
  nlPostalCodeRegex,
  streetNumberRegex,
} from "./regex"

export function billingAddress(
  values: IFullCheckoutFormValues["billingAddress"],
  t: TFunction<["checkout" | "checkout-common"]>
) {
  return z.object({
    firstName: firstName(t),
    lastName: lastName(t),
    email: email(t),
    phoneCountry: z.string().optional(),
    phoneNumber: phoneNumber(t),
    businessCustomer: businessCustomer(t).default(false),
    ...(values?.businessCustomer && {
      companyName: companyName(t),
      vatNumberRequired: vatNumberRequired(t).default(false),
      ...(values?.vatNumberRequired && {
        vatNumber: vatNumber(t),
      }),
    }),
    country: country(t),
    street: street(t),
    streetNumber: streetNumber(t),
    apartment: apartment(t).optional(),
    // Belgian-specific fields
    ...(values?.country === "BE" && {
      municipality: municipality(t),
    }),
    // Dutch-specific fields
    ...(values?.country === "NL" && {
      postalCode: dutchPostalCode(t),
      city: city(t),
    }),
    // Other countries
    ...(values?.country !== "NL" &&
      values?.country !== "BE" && {
        postalCode: otherPostalCode(t),
        city: city(t),
      }),
  })
}

export function shippingAddress(
  values: IFullCheckoutFormValues["shippingAddress"],
  t: TFunction<["checkout" | "checkout-common"]>
) {
  return z.object({
    firstName: firstName(t),
    lastName: lastName(t),
    street: street(t),
    streetNumber: streetNumber(t),
    apartment: apartment(t).optional(),
    country: country(t),
    // Belgian-specific fields
    ...(values?.country === "BE" && {
      municipality: municipality(t),
    }),
    // Dutch-specific fields
    ...(values?.country === "NL" && {
      postalCode: dutchPostalCode(t),
      city: city(t),
    }),
  })
}

function firstName(t: TFunction<["checkout" | "checkout-common"]>) {
  const invalidFieldMessage = `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:FirstName").toLowerCase()}`

  return z
    .string({ required_error: t("checkout-common:FillInFirstName") })
    .min(1, t("checkout-common:FillInFirstName"))
    .max(255, `${t("checkout-common:FirstName")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(emojiRegex, invalidFieldMessage)
    .regex(forbiddenCharactersRegex, `${invalidFieldMessage}: <>/"=()`)
}

function lastName(t: TFunction<["checkout" | "checkout-common"]>) {
  const invalidFieldMessage = `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:LastName").toLowerCase()}`

  return z
    .string({ required_error: t("checkout-common:FillInLastName") })
    .min(1, t("checkout-common:FillInLastName"))
    .max(255, `${t("checkout-common:LastName")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(emojiRegex, invalidFieldMessage)
    .regex(forbiddenCharactersRegex, `${invalidFieldMessage}: <>/"=()`)
}

function street(t: TFunction<["checkout" | "checkout-common"]>) {
  const invalidFieldMessage = `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:StreetName").toLowerCase()}`

  return z
    .string({ required_error: t("checkout-common:FillInStreetName") })
    .min(1, t("checkout-common:FillInStreetName"))
    .max(255, `${t("checkout-common:StreetName")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(emojiRegex, invalidFieldMessage)
    .regex(forbiddenCharactersStreetCityRegex, `${invalidFieldMessage}: <>"=`)
}

function streetNumber(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInStreetNumber") })
    .min(1, t("checkout-common:FillInStreetNumber"))
    .max(5, `${t("checkout-common:StreetNumber")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(streetNumberRegex, t("checkout-common:InvalidStreetNumber"))
}

function apartment(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string()
    .max(10, `${t("checkout-common:Addition")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(
      forbiddenCharactersRegex,
      `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:Addition").toLowerCase()}: <>/"=()`
    )
}

function phoneNumber(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInPhoneNumber") })
    .refine((value) => isValidPhoneNumber(value, formulaConfig.formulaCountry, true, true), {
      message: t("checkout-common:FillInValidPhoneNumber"),
    })
}

function country(_t: TFunction<["checkout" | "checkout-common"]>) {
  return z.enum(countrySelectOptions)
}

function municipality(t: TFunction<["checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInPostalCode") })
    .min(1, t("checkout-common:FillInPostalCode"))
    .regex(beMunicipalityRegex, t("checkout-common:InvalidPostalCode"))
    .regex(
      forbiddenCharactersRegex,
      `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:PostalCode").toLowerCase()}: <>/"=()`
    )
}

function dutchPostalCode(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInPostalCode") })
    .min(1, t("checkout-common:FillInPostalCode"))
    .regex(nlPostalCodeRegex, t("checkout-common:InvalidPostalCode"))
    .regex(
      forbiddenCharactersRegex,
      `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:PostalCode").toLowerCase()}: <>/"=()`
    )
}

function otherPostalCode(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInPostalCode") })
    .min(1, t("checkout-common:FillInPostalCode"))
    .regex(
      forbiddenCharactersRegex,
      `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:PostalCode").toLowerCase()}: <>/"=()`
    )
}

function city(t: TFunction<["checkout" | "checkout-common"]>) {
  const invalidFieldMessage = `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:City").toLowerCase()}`

  return z
    .string({ required_error: t("checkout-common:FillInCity") })
    .min(1, t("checkout-common:FillInCity"))
    .max(40, `${t("checkout-common:City")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(emojiRegex, invalidFieldMessage)
    .regex(forbiddenCharactersStreetCityRegex, `${invalidFieldMessage}: <>"=`)
}

function businessCustomer(_t: TFunction<["checkout" | "checkout-common"]>) {
  return z.boolean()
}

function companyName(t: TFunction<["checkout" | "checkout-common"]>) {
  const invalidFieldMessage = `${t("checkout-common:InvalidCharacters")} ${t("checkout-common:CompanyName").toLowerCase()}`
  return z
    .string({ required_error: t("checkout-common:FillInCompanyName") })
    .min(1, t("checkout-common:FillInCompanyName"))
    .max(80, `${t("checkout-common:CompanyName")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .regex(emojiRegex, invalidFieldMessage)
    .regex(forbiddenCharactersRegex, `${invalidFieldMessage}: <>/"=()`)
}

function vatNumberRequired(_t: TFunction<["checkout" | "checkout-common"]>) {
  return z.boolean()
}

function vatNumber(t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInValidVATNumber") })
    .min(1, t("checkout-common:FillInVATNumber"))
    .transform((value, context) => {
      const transformed = value
        .toUpperCase()
        .replace(/BE|NL/g, "")
        .replace(/\./g, "")
        .replace(/\s/g, "")
        .replace(/_/g, "")
      const regex = formulaConfig.country === "BE" ? /^([0-1][0-9]{9})$/ : /^[0-9]{9}B[0-9]{2}$/

      if (!regex.test(transformed)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("checkout-common:FillInValidVATNumber"),
        })

        return z.NEVER
      }

      return `${formulaConfig.country}${transformed}`
    })
}
